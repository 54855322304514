import { HTMLProps } from "@/types";
import { forwardRef, useId } from "react";
import Feild, { FeildProps } from "./Feild";

const classes = {
  textAreaElement: {
    base: ` px-0.75em py-0.5em bg-transparent focus:outline-transparent line-height-1`,
    disabled: "",
  },
};

type TextAreaProps = {
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  setValue?: (val: string) => void;
} & HTMLProps<"textarea">;

type Ref = HTMLTextAreaElement;
type AllProps = React.ComponentPropsWithoutRef<"textarea"> & TextAreaProps & Omit<FeildProps , 'suffix' | 'prefix' | 'id' | 'children'>;

const TextArea = forwardRef<Ref, AllProps>(function ({
  placeholder,
  hint,
  label,
  name,
  required,
  disabled,
  errorMessage,
  hasErrorMessage = true,
  setValue,
  onChange,
  id: idFromProps,
  ...textAreaProps
}, ref) {

  const generatedIdId = useId();
  const id = idFromProps ?? generatedIdId;
  const hintId = hint ? `${id}-hint` : "";
  const errorMessageId = errorMessage ? `${id}-error-message` : "";

  return (
    <Feild
      hint={hint}
      label={label}
      errorMessage={errorMessage}
      hasErrorMessage={hasErrorMessage}
      disabled={disabled}
      required={required}
      name={name}
      id={id}
    >
      <textarea
        {...textAreaProps}
        ref={ref}
        name={name}
        id={id}
        className={` ${classes.textAreaElement.base}  ${disabled && classes.textAreaElement.disabled} `}
        aria-describedby={`${errorMessageId} ${hintId}`}
        aria-invalid={!!errorMessage}
        onChange={(e) => {
          setValue?.(e.target.value);
          onChange?.(e);
        }}
      ></textarea>
    </Feild>
  );
});

TextArea.displayName = "TextArea";
export default TextArea;